import Vue from 'vue';

export default Vue.extend({
	data () {
		return {
			items: ['채들', '채들크루', '채들어드민'],
			dialog: false,
			dialog_video: true,
		}
	},
});