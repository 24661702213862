
































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import ViewModel from '@/views/contact/Frequently.ts';
export default ViewModel;
